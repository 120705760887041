<div [class]="'theme-wrapper ' + (theme$ | async)">

  <mat-sidenav-container>

    <mat-sidenav #sidenav mode="push">
      <div class="branding">
        <img [src]="logo" [alt]="logo" style="float: left; height: 18px; margin-top: 15px; margin-left: 10px;"/>
        <!-- <span>{{ 'app.title.short' | translate}}</span> -->
      </div>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of navigationSideMenu" (click)="sidenav.close()" [routerLink]="[item.link]" routerLinkActive="active">
          {{item.label | translate}}
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <div class="wrapper">

      <div class="toolbar" [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'" [class.mat-elevation-z4]="(stickyHeader$ | async)">
        <mat-toolbar color="primary">
          <div class="container">
            <button mat-icon-button class="d-md-none" (click)="sidenav.open()" style="float: left;">
              <fa-icon icon="bars"></fa-icon>
            </button>

            <span class="branding spacer center d-inline d-sm-none">
              <img [src]="logo_sm" [alt]="logo" routerLink="" style="float: left; height: 26px; margin-left: 10px; margin-top: 8px;"/>
            </span>
            <span class="branding spacer center d-none d-sm-inline d-md-none">
              <img [src]="logo" [alt]="logo" routerLink=""/>
            </span>
            <span class="branding spacer d-none d-md-inline">
              <img [src]="logo"[alt]="logo" routerLink="" />
            </span>

            <div style="float: right;">

              <span class="d-none d-md-inline">
                <button mat-button class="nav-button" *ngFor="let item of navigation" [routerLink]="[item.link]" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">
                  {{item.label | translate | uppercase}}
                </button>
              </span>

              <button mat-button mat-stroked-button color="warn" *ngIf="!(isAuthenticated$ | async)" (click)="googleLogin()">
                <img src="assets/google-logo.png" alt="google" width="18px" style="margin-top: -2px;" class="mr-2">{{ 'app.menu.login' | translate | uppercase }}
              </button>

              <button *ngIf="(isAuthenticated$ | async)" mat-icon-button [matMenuTriggerFor]="toolbarUserMenu">
                <fa-icon icon="user-circle"></fa-icon>
              </button>
              <mat-menu #toolbarUserMenu="matMenu">
                <button mat-menu-item routerLink="" disabled><span>{{ 'app.menu.profile' | translate }}</span></button>
                <button mat-menu-item (click)="onLogoutClick()"><span>{{ 'app.menu.logout' | translate }}</span></button>
              </mat-menu>

              <!-- <span *ngIf="language$ | async as language">
                <mat-select [ngModel]="language" (selectionChange)="onLanguageSelect($event)" style="opacity: 0.4;">
                  <mat-option *ngFor="let l of languages" [value]="l">
                    {{ l.toUpperCase() }}
                  </mat-option>
                </mat-select>
              </span> -->
              <!-- <span *ngIf="theme$ | async as theme">
                <mat-select [ngModel]="theme" (selectionChange)="onThemeSelect($event)" style="width: 60px; opacity: 0.4;">
                  <mat-option *ngFor="let t of themes" [value]="t.value.toLowerCase()">
                    {{ t.label.toUpperCase() }}
                  </mat-option>
                </mat-select>
              </span> -->
            </div>
          </div>
        </mat-toolbar>
      </div>

      <div class="content" [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.data && o.activatedRoute.routeConfig.data.title">
        <router-outlet #o="outlet"></router-outlet>
      </div>

      <div class="footer d-none">
        <div class="container">
          <div class="row">
            <div class="col-12 signature">
              <small>
                &#169; <span class="year">{{year}}</span> ~ Martin Nzuki
                <br class="d-block d-sm-none">
                <a style="opacity: 0.4;" [matTooltip]="'app.footer.changelog' | translate" matTooltipPosition="before" href="https://github.com/nzuqi/movies-galore/blob/master/CHANGELOG.md">v{{version}} <span *ngIf="!isProd">[{{envName}}]</span></a>
              </small>
            </div>
          </div>
        </div>
      </div>

    </div>

  </mat-sidenav-container>

</div>
